import request from '@/utils/request'

export const GetLibararyList = function (data) {
    return request({
        method: 'GET',
        url: '/API/Library/GetDataList',
        params: data,
    })
}
export const GetDataById = function (data) {
    return request({
        method: 'GET',
        url: '/API/Library/GetData',
        params: data,
    })
}

export const DownloadFile = function (data) {
    return request({
        method: 'GET',
        url: '/API/Library/DownloadFile',
        params: data,
    })
}