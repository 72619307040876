<template>
    <div class="search">
        <template v-for="(item, index) in params">
            <div
                :class="['item', item.class]"
                :key="index"
                v-if="item.type == 'select'"
            >
                <a-select
                    size="large"
                    placeholder="请选择"
                    v-model="item.value"
                >
                    <a-icon slot="suffixIcon" type="caret-down" />
                    <a-select-option
                        v-for="option in item.options"
                        :key="option.value"
                        :value="option.value"
                    >
                        {{ option.label }}
                    </a-select-option>
                </a-select>
            </div>
            <div
                :class="['item', item.class]"
                :key="index"
                v-if="item.type == 'input'"
            >
                <a-input
                    :placeholder="item.name"
                    v-model="item.value"
                    allow-clear
                    @keyup.enter="search"
                />
            </div>
        </template>
        <div class="item btn">
            <a-button class="submit" type="primary" @click="search">
                检索
            </a-button>
        </div>
    </div>
</template>
<script>
export default {
    props: ['paramsObj'],
    data() {
        return {
            params: [
                {
                    name: '全部',
                    type: 'select',
                    class: 'model',
                    value: 'key',
                    key: 'type',
                    options: [
                        {
                            value: 'key',
                            label: '全部',
                        },
                        {
                            value: 'Title',
                            label: '标题',
                        },
                        {
                            value: 'Info',
                            label: '简介',
                        },
                        {
                            value: 'Tags',
                            label: '标签',
                        },
                    ],
                },
                {
                    name: '标题',
                    type: 'input',
                    value: '',
                    class: 'tag',
                    key: 'value',
                },
            ],
        }
    },
    watch: {
        paramsObj(v) {
            this.mergeObj(v, this.params)
        },
    },
    methods: {
        search() {
            let obj = {}
            this.params.forEach(item => {
                const { key, value } = item
                obj[key] = value
            })
            console.log(obj)
            this.$emit('search', obj)
        },
        mergeObj(newObj, oldObjList) {
            const keys = Object.keys(newObj)
            oldObjList.forEach(item => {
                const idx = keys.find(ele => ele == item.key)
                if (idx) {
                    item.value = newObj[idx]
                }
            })
        },
    },
    mounted() {
        if (this.paramsObj) {
            this.mergeObj(this.paramsObj, this.params)
        }
    },
}
</script>
<style scoped lang="less">
.search {
    display: flex;
    padding: 25px 0;
    // background-color: #eee;
    .item {
        margin-right: 10px;
        width: 100%;
        &:last-of-type {
            margin-right: 0;
        }
    }
    .btn {
        width: 120px;
        flex-shrink: 0;
        flex-grow: 0;
    }
    .keyword {
        width: 380px;
        flex-shrink: 0;
        flex-grow: 0;
    }
}
.ant-select {
    width: 100%;
}
:deep(.ant-input-affix-wrapper) {
    &:hover {
        .ant-input {
            border-color: #79f9d9 !important;
        }
    }
}
:deep(.ant-input) {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    background-color: #fff;
    border-radius: 3px;
    padding: 0 20px;
    border-color: transparent;
    &:hover {
        border-color: #79f9d9 !important;
    }
}
.submit {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    color: #000;
    background-color: #79f9d9;
    letter-spacing: 0;
    font-weight: bold;
    &:focus,
    &:hover {
        background-color: #79f9d9;
        border-color: #79f9d9;
        color: #000;
        box-shadow: 0 0 10px 0 rgb(121, 249, 217, 0.8);
    }
}
:deep(.ant-select-selection) {
    background-color: #fff;
}
.model {
    width: 300px !important;
}
</style>
