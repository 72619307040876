<template>
    <div class="auto-1440">
        <!-- <NotOnline/> -->
        <Breadcrumb type="grey" />
        <div class="list_wrap">
            <div class="left">
                <h1>
                    <div class="cn">参考资料</div>
                    <!-- <div class="en">Database</div> -->
                </h1>
                <Side @select="select" />
            </div>
            <div class="right">
                <Search @search="onSearch" />
                <div class="sum">
                    <span>
                        共计{{ total }}个，当前页面呈现{{ list.length }}个
                    </span>
                </div>
                <div class="list">
                    <a-list item-layout="horizontal" :data-source="list" :loading="loading">
                        <a-list-item slot="renderItem" slot-scope="item">
                            <Item :data="item" :key="item.Id" />
                        </a-list-item>
                    </a-list>
                    <Pagination :pageSize="params.pageSize" :total="total" @pageChange="pageChange"
                        :current="params.pageIndex" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '~/components/common/Breadcrumb'
import Search from '~/components/Library/List/Search'
import Item from '~/components/Library/List/Item'
import Side from '~/components/Library/List/Side'
import Pagination from '~/components/common/Pagination'
import { GetLibararyList } from '@/api/libarary'
import NotOnline from '~/components/common/NotOnline'
import { mapState } from 'vuex'
export default {
    metaInfo() {
        return {
            title: this.mateInfo?.Title || '文物出版社',
            meta: [
                {
                    name: 'keyWords',
                    content: this.mateInfo?.Title || '文物出版社',
                },
                {
                    name: 'description',
                    content: this.mateInfo?.Title || '文物出版社',
                },
            ],
        }
    },
    computed:{
        ...mapState({
            navList: state => state.app.navList,
        }),
        mateInfo(){
            return this.navList.find(i => i.LinkUrl.indexOf('#/library/list') !== -1)
        }
    },
    components: {
        Breadcrumb,
        Side,
        Search,
        Item,
        Pagination,
        NotOnline,
    },
    data() {
        return {
            list: [],
            total: 0,
            params: {
                pageIndex: 1,
                pageSize: 6,
                categoryId: '',
            },
            activeID: '',
            title: '',
            loading: false,
        }
    },
    methods: {
        onSearch(obj) {
            const { type, value } = obj
            this.params = { pageIndex: 1, pageSize: 6, categoryId: '' }
            this.params[type] = value
            this.params.categoryId = this.activeID
            this.getList()
        },
        async getList() {
            this.loading = true
            const { data, total } = await GetLibararyList(this.params)
            this.list = data
            this.total = total
            this.loading = false
        },
        // 侧边栏选中
        select(key) {
            this.activeID = key
            // let where = JSON.stringify({
            //     CategoryId: this.activeID,
            //     Title: this.title,
            // })
            this.params.categoryId = this.activeID
            this.params.pageIndex = 1
            this.getList()
        },
        pageChange(obj) {
            this.params.pageIndex = obj.page
            this.getList()
        },

    },
    created() {
        this.getList()
    },
}
</script>
<style lang="less" scoped>
.breadcrumb {
    // padding-bottom: 10px;
    border-bottom: 0;
}

h1 {
    margin-bottom: 30px;

    .cn {
        font-size: 28px;
        color: rgb(0, 15, 35);
        font-weight: bold;
    }

    .en {
        font-size: 16px;
        color: rgb(136, 136, 136);
        font-weight: normal;
    }
}

.list_wrap {
    display: flex;
    background-color: #f8f8f8;
    background-color: #f9f9f9;
    padding: 0.6rem;
    margin-bottom: 80px;

    .left {
        width: 280px;
        padding-right: 60px;
        border-right: 1px solid #dee2e8;
    }

    .right {
        flex: 1;
        padding-left: 60px;
    }
}

.sum {
    line-height: 60px;
    text-align: right;
    font-size: 18px;
    color: rgb(136, 136, 136);
    border-bottom: 1px solid #dee2e8;
}

:deep(.ant-list) {
    min-height: 100px;
    margin-bottom: 50px;

    .ant-list-item {
        border-bottom: 0;
        padding: 0;
        border-bottom: 1px solid #dee2e8;
        // margin-bottom: 10px;
    }
}
</style>
