<template>
    <div class="item">
        <div class="txt">
            <h2 class="dot" @click="goDetail">{{ data.Title }}</h2>
            <div class="des dot2">{{ data.Info }}</div>
            <div class="tag_box">
                <Tag :name="cateName" />
            </div>
        </div>
        <div class="handle">
            <div class="handle_item" @click="goDetail">
                <svg-icon icon-class="icon_eye"></svg-icon>
                <span>查看</span>
            </div>
            <div class="handle_item" @click="ToggleCollect(data.Id)">
                <svg-icon icon-class="icon_collect"></svg-icon>
                <span>收藏</span>
            </div>
            <div class="handle_item" @click="download">
                <a-icon type="download" />
                <span>下载</span>
            </div>
        </div>
    </div>
</template>
<script>
import Tag from '~/components/common/Tag'
import { ToggleCollectLibrary } from '@/api/collection'
import { DownloadFile } from '@/api/libarary'
export default {
    props: ['data'],
    components: {
        Tag,
    },
    data() {
        return {
            cate: [],
            cateName: '',
        }
    },
    computed: {
        category() {
            const data = this.cate.find(
                item => item.Id == Number(this.data.CategoryId)
            )
            console.log(data)
            return data
        },
    },
    methods: {
        goDetail() {
            this.$router.push({
                name: 'library-info',
                params: { id: this.data.Id },
            })
        },
        async download() {
            var that = this
            if (this.data.FileUrl == "") {
                const { data } = await DownloadFile({ id: this.data.Id })
                console.log(data)
                this.data.FileUrl=data
            }

            const xhr = new XMLHttpRequest();
            xhr.open("get", this.data.FileUrl);
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (this.status === 200) {
                    const blob = this.response;
                    const url = URL.createObjectURL(blob);
                    // 将转化后 url 赋值给 vue-pdf 插件
                    // that.link = url;
                    const a = document.createElement('a')
                    a.href = url
                    a.target = '_blank'
                    a.download = that.data.Title
                    a.click()
                }
            };
            xhr.send(null);
        },
        // 收藏
        ToggleCollect(id) {
            ToggleCollectLibrary(id).then(res => {
                this.$message.success(res.msg)
                this.info.IsCollection = !this.info.IsCollection
            }).catch(err => {

            })
        }
    },

    async mounted() {
        console.log(this.data.CategoryId)
        this.cate = await this.$store.dispatch('library/getCate')
        // console.log(this.data.CategoryId)
        const data = this.cate.find(
            item => item.Id == Number(this.data.CategoryId)
        )
        // console.log(data)
        this.cateName = data ? data.Title : ''
    },
}
</script>
<style lang="less" scoped>
.item {
    display: flex;
    padding: 20px 0;
    flex: 1;
    // background-color: #f5f5f5;
    transition: 0.3s;

    // border-bottom: 1px solid #dee2e8;
    :deep(.tag) {
        // max-width: 150px;
        text-align: center;
    }

    &:hover {
        box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014,
            0 9px 28px 8px #0000000d;

        h2 {
            color: #f49133 !important;
        }
    }

    .tag_box {
        flex-shrink: 0;
        // min-width: 120px;
        margin-top: 15px;

        .tag {
            margin-bottom: 0;
        }
    }

    .txt {
        flex: 1;
        // padding-left: 30px;
        padding-right: 20px;

        h2 {
            font-size: 18px;
            color: rgb(51, 51, 51);
            line-height: 1;
            font-weight: bold;
            margin-bottom: 13px;
            cursor: pointer;
        }

        .des {
            font-size: 16px;
            color: rgb(85, 85, 85);
            line-height: 1.5;
            // height: 3em;
        }
    }

    .handle {
        flex-shrink: 0;
        padding-left: 10px;
        display: flex;
        align-items: center;
        border-left: 1px solid #d5d5d5;

        .handle_item {
            width: 50px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            color: #999;
            transition: 0.3s;
            cursor: pointer;

            .anticon {
                width: 1em;
                height: 1em;
                font-size: 1.2em;
                font-weight: bold;
            }

            &:hover {
                color: #f6a75c;
            }
        }
    }
}
</style>