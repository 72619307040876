<template>
    <div :class="type">
        <a-menu style="width: 100%" mode="inline" :defaultOpenKeys="openKeys" @select="select">
            <a-sub-menu v-for="item in data" :key="item.Id">
                <span slot="title"><span class="title">{{ item.Title }}</span></span>
                <a-menu-item v-for="subitem in item.children" :key="subitem.Id">
                    <span class="subtitle">{{ subitem.Title }}<i></i></span>
                </a-menu-item>
            </a-sub-menu>
        </a-menu>
    </div>
</template>
<script>
export default {
    props: {
        type: {
            type: String,
            default: 'default'
        },
        data: {
            type: Array,
            default: []
        },
        openKeys: Array
    },
    methods: {
        select({ item, key, selectedKeys }) {
            this.$emit('select', key)
        }
    }
}
</script>
<style lang="less" scoped>
:deep(.ant-menu) {
    .ant-menu-submenu-title {
        padding: 0 20px !important;
        margin: 0;
        line-height: 60px !important;
        height: 60px !important;
        font-size: 18px;
        color: rgb(0, 0, 0);
        font-weight: bold;

        &:hover {

            .ant-menu-submenu-arrow::before,
            .ant-menu-submenu-arrow::after {
                background: linear-gradient(to right, #f6a75c, #f6a75c);
            }
        }
    }

    .ant-menu-sub {
        padding: 10px 0;
    }

    .ant-menu-item {
        padding: 0 20px !important;
        margin: 0 !important;
        line-height: 55px !important;
        height: 55px !important;
        font-size: 16px;
        color: rgb(85, 85, 85);

        .subtitle {
            display: block;
            position: relative;

            i {
                position: absolute;
                top: 50%;
                right: -5px;
                width: 0.1rem;
                transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                transform: translateY(-0.02rem);
                opacity: 0;

                &::before {
                    position: absolute;
                    width: 0.06rem;
                    height: 0.015rem;
                    background: #fff;
                    background: rgba(0, 0, 0, 0.65);
                    background-image: linear-gradient(to right, #f6a75c, #f6a75c);
                    background-image: none;
                    border-radius: 0.02rem;
                    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                    content: '';
                    transform: rotate(45deg) translateY(-0.022rem);
                }

                &:after {
                    position: absolute;
                    width: 0.06rem;
                    height: 0.015rem;
                    background: #fff;
                    background: rgba(0, 0, 0, 0.65);
                    background-image: linear-gradient(to right, #f6a75c, #f6a75c);
                    background-image: none;
                    border-radius: 0.02rem;
                    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                    content: '';
                    transform: rotate(-45deg) translateY(0.022rem);
                }
            }
        }
    }

    .ant-menu-item-selected {
        background-color: transparent !important;
        color: #f6a75c;

        &::after {
            display: none;
        }

        .subtitle {
            i {
                opacity: 1;
            }
        }
    }
}

.default {
    :deep(.ant-menu-submenu-title) {
        background-color: #dce8f3;
    }

    :deep(.ant-menu-sub) {
        background-color: #f4f7fa;
    }
}
</style>