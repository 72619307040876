<template>
    <div>
        <a-spin :spinning="loading">
            <div class="green">
                <a-menu
                    style="width: 100%"
                    mode="inline"
                    :defaultOpenKeys="openKeys"
                    @select="select"
                >
                    <template v-for="item in category">
                        <a-sub-menu
                            :key="item.Id"
                            v-if="item.children.length > 0"
                        >
                            <span slot="title" :title="item.Title">
                                <span class="title">{{ item.Title }}</span>
                            </span>
                            <a-menu-item
                                v-for="subitem in item.children"
                                :key="subitem.Id"
                            >
                                <span class="subtitle" :title="item.Title">
                                    {{ subitem.Title }}
                                    <i></i>
                                </span>
                            </a-menu-item>
                        </a-sub-menu>
                        <a-menu-item :key="item.Id" v-else>
                            <span class="subtitle" :title="item.Title">
                                {{
                                    item.Title.length > 13
                                        ? item.Title.substr(0, 10) + '...'
                                        : item.Title
                                }}
                                <i></i>
                            </span>
                        </a-menu-item>
                    </template>
                    <!--   -->
                </a-menu>
            </div>
        </a-spin>
    </div>
</template>
<script>
import Sidebar from '~/components/common/Sidebar'
import { GetLibararyCate } from '@/api/common'
export default {
    components: {
        Sidebar,
    },
    data() {
        return {
            category: [],
            openKeys: [],
            loading: false,
        }
    },
    methods: {
        async getCate() {
            this.loading = true
            let data = await this.$store.dispatch('library/getCate')
            const arr = []
            data.forEach((item, i) => {
                if (!item.ParentId) {
                    arr.push(item)
                }
                this.openKeys.push(item.Id)
            })
            arr.forEach((item1, i) => {
                item1.children = data.filter(
                    item2 => item2.ParentId == item1.Id
                )
            })
            this.category = arr
            console.log(this.category)
            this.loading = false
        },
        select({ item, key, selectedKeys }) {
            this.$emit('select', key)
        },
    },
    created() {
        console.log(4)
        this.getCate()
    },
}
</script>
<style lang="less" scoped>
@import url('~@/assets/css/sidebar.less');
</style>